import Glide from '@glidejs/glide/dist/glide.modular.esm';
const config = {
  type: 'carousel',
  perView: 4,
  breakpoints: {
    800: {
      perView: 1
    }
  }
};
new Glide('.glide', config).mount();
